
.contact-form {
    //margin: 50px 50px;
    margin: -25px 50px 50px;
    @media only screen and (max-width: 800px) {
        margin: 20px auto;
    }
    > hr {
        border: 1px solid #f0f0f0;
        margin-bottom: 50px;
    }
    .ant-form {
        margin: 0 auto;
        max-width: 50%;
        
        @media screen and (max-width: 768px) {
            max-width: 80%;
        }

        .ant-col-hide {
            display: none;
        }
        
    }
}

@primary-color: #5865F2;@font-size-base: 20px;@border-radius-base: 20px;
.contact-whatsapp-fix {
    position: fixed;
    right: 12px;
    bottom: 60px;
    height: 32px;
    width: max-content;
    z-index: 99;
    display: flex;
    align-items: center;
    top: 50%;

    a {
        display: flex;
        bottom: 90px;
        align-items: center;
        justify-content: center;
        width: 65px;
        height: 65px;
        border-radius: 35%;
        background: #04d361;
    }
}
@primary-color: #5865F2;@font-size-base: 20px;@border-radius-base: 20px;
@import "../../styles/variables.less";

.banner {
    position: relative;

    &__wrapper{
      width: 100%;
      max-width: @site-max-width;
      margin-top: -32px;

      @media only screen and (max-width: @header-mobile-width) {
        margin-top: 0;
        overflow: hidden;
      }
    }

    &__highlight-galaxies {
        height: 100vh;
        max-height: 800px; 
        overflow: hidden;
        background-color: #010119 !important;
        background-size: cover;
        position: relative;

        > div {
            top: 0;
            width: 100%;
            margin: 0 auto;
            z-index: 2;
            text-align: center !important;
            place-items: center;

            > div > div {
                margin: 0 auto;

                p {
                    color: #fff;
                    line-height: 26px;
                    font-weight: 400;
                    padding: 5px;
                    margin: 5px;
                }
            }
        }

        h1 {
            color: #fff!important; 
            font-size: 44px !important;
            line-height: 60px;
            font-weight: 700;
            padding: 0;
            margin: 0;
            max-width: 720px!important;
            display: inline-block!important;
            font-weight: 700!important;
        }

        @media only screen and (max-width: @header-mobile-width) {
            > div {
               padding-left: 15px;
               padding-right: 15px;
    
                > div > div {
                    margin: 0 auto;
    
                    p {
                        color: #fff;
                        font-size: 17px;
                    }
                }
            }

            h1 {
                font-size: 32px !important;
            }
        }

       

    }

    &__sub-banner {
        display: none;
        position: absolute;
        bottom: 32px;
        left: 0;
        background-color: @banner-sub-banner-bg;
        padding: 15px 0;
        width: 100% !important;
        text-align: center;
        font-weight: bold;
        font-size: 1.3em;
        letter-spacing: .025em;
        box-shadow: @banner-sub-banner-box-shadow;
        color: @banner-sub-banner-color;
        @media only screen and (max-width: @header-mobile-width) {
            font-size: 1.2em;
            bottom: 0
        }
    }

    &__default-sub-banner {
        padding-top: calc(1.8em + 30px) !important;
        padding-bottom: calc(1.8em + 30px) !important;
        .banner__sub-banner {
            display: block;
        }
        > div > div {
            text-align: center;
            h1 {
                color: #fff!important; 
                font-size: 44px !important;
                line-height: 60px;
                font-weight: 700;
                padding: 0;
                margin: 0;
                max-width: 720px!important;
                display: inline-block!important;
                font-weight: 700!important;
                margin-bottom: 15px !important;
                @media only screen and (max-width: @header-mobile-width) {
                    font-size: 32px !important;
                }
            }
            > div {
                p {
                    width: 90%;
                    margin: 0 auto ;
                    margin-bottom: 0;
                    &:nth-last-child(1) {
                        margin-bottom: 1em;
                    }
                    @media only screen and (max-width: @header-mobile-width) {
                        font-size: 17px;
                    }
                }   
            }
        }

        
        @media only screen and (max-width: @header-mobile-width) {
            > div > div > div {
                margin: auto;
            }
        }
    }

    &__default, &__default-sub-banner {
        .banner__darken-bg {
            display: none;
        }
    }
    &__secondary {
        position: relative;
        height: calc(40vh - @header-size);
        text-align: left;
        @media only screen and (max-width: @header-mobile-width) {
            height: calc(30vh - @header-size-mobile);
        }
        div:nth-child(1) {
            z-index: 2;
            h2 {
                text-shadow: 0 0 10px @banner-secondary-text-shadow;
            }
        }
        .banner__darken-bg {
            width: 100%;
            height: 100%;
            background-color: @banner-secondary-darken-bg-color;
            position: absolute;
            z-index: 1;
        }
        @media only screen and (max-width: @header-mobile-width) {
            height: calc(33vh - @header-size-mobile) !important;
        }
    }
    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
        padding: 0 calc((100% - @header-desktop-width) / 2);
        width: 100%;
        background-color: @site-background;
        background-size: cover;
        background-attachment: fixed;
        background-repeat: no-repeat;

        @media only screen and (max-width: @header-mobile-width) {
            height: calc(100vh - @header-size-mobile);
            min-height: unset;
            text-align: center;
            padding: 0;
        }
        > div {
            width: 100%;
            > div {
                > div {
                    height: auto;
                    > h1 {
                        margin-bottom: 0;
                        font-weight: 200;
                        font-size: 3.5em;
                        color: #fff;
                        letter-spacing: .01em;
                        @media only screen and (max-width: @header-mobile-width) {
                            font-size: 2em;
                            line-height: 1.2em;
                            margin-bottom: 10px;
                        }
                    }
                    @media only screen and (max-width: @header-mobile-width) {
                        width: 100%;
                        padding: 0 20px
                    }
                    > div {
                        > p {
                            color: #fff;
                            @media only screen and (max-width: @header-mobile-width) {
                                line-height: 1.5em;
                            }
                        }
                    }
                }        
            }
            .actions {
                position: relative;
                display: inline-block;
                padding-left: 0;
                text-align: center;
                .actions__item {
                    h4 {
                        font-size: 1.0em;
                        margin-bottom: 0.1em;
                    }
                    p {
                        font-size: 1.1em;
                        margin-bottom: 0.1em;
                    }
                    a {
                        font-size: 1em
                    }
                    &:hover {
                        h4 {
                            color: #000000;
                        }
                    }
                }
            }
        }
    }
}
    
@primary-color: #5865F2;@font-size-base: 20px;@border-radius-base: 20px;
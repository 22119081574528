@import "../../styles/variables.less";

.listing {
    position: relative;
        //background-color: #010119 !important;

    &__tab-list {
        padding: 124px 0;
        position: relative;

        &:nth-last-child(2) {
            padding-bottom: 0;
        }

        // .listing-background {
        //     position: absolute;
        //     inset: 0;
        //     width: 100%;
        //     height: 100%;
        //     padding-bottom: 4vw;
        //     background-color: #fff;
        //     transform: rotate(3.55deg);
        //     transform-origin: top right;
        // }

        // &::after {
        //     content: '';
        //     position: absolute;
        //     width: 100%;
        //     bottom: -50px;
        //     padding-bottom: 4vw;
        //     background-color: #fff;
        //     transform: rotate(3.55deg);
        //     transform-origin: top right;
        //     z-index: 40;
        // }
    }

    &__operation-netuno {
        background: #010119 !important;
        padding: 120px 0;
        
        h1 {
            font-size: @font-heading;
            text-align: center;
            color: #fff;
            font-weight: 700;
        }
    }

    .text__title-border {
        display: flex;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        justify-content: center;
        margin-bottom: 60px;
        width: 1.5px;
        height: 80px;
        background: linear-gradient(180deg,#1178FF ,rgba(153,109,255,0));
        margin: 0 auto;
    }
}

@primary-color: #5865F2;@font-size-base: 20px;@border-radius-base: 20px;
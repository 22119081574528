@import "../../styles/variables.less";

.ant-layout-header {
    padding: 0 calc((100% - @header-desktop-width) / 2) !important;
    position: fixed;
    display: flex;
    justify-content: center;
    flex-direction: row;
    z-index: 1000;
    top: 0;
    width: 100%;
    height: @header-size !important;
    line-height: @header-size !important;
    background: #010119!important;
    transition: all .3s ease-out;
    box-shadow: inset 0 -1px 0 #29292e;
   
    @media (max-width: @header-mobile-width) {
      padding: 0 20px !important;
      height: @header-size-mobile !important;
      line-height: @header-size-mobile !important;
    }

    &__wrapper{
      width: 90%;
      margin: 0 auto;
      display: flex;

    }

    >*:not(.menu-burger-open) {
        z-index: 3;
    }

    &.header-burger-open .menu {
        z-index: 0;
    }

    .logo {
        display: block;
        width: @logo-width;
        height: auto;

        a {
            img {
                width: auto;
                height: 50px;
            }
        }

        @media only screen and (max-width: @header-mobile-width) {
            width: auto;
            max-width: 400px;
            a {
              img {
                  max-width: 124px;
                  height: auto;
              }
          }
        }
    }

    .menu-languages {
        line-height: @header-line-height !important;
        background-color: transparent;
        color: @header-menu-color;
        border-bottom: none;
        display: flex;
        width: 150px !important;
        .ant-menu-submenu-title {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .ant-menu-submenu-title:hover {
            color: @header-menu-color-hover !important;
        }

        li {
            margin-bottom: 20px !important;
            display: inline;

            &:hover {
                border-color: @header-menu-color-hover !important;
            }
        }
    }

    .menu {
        width: 100%;
        line-height: @header-line-height !important;

        @media only screen and (max-width: @header-mobile-width) {
            display: none;
        }

        ul {
            width: 100%;
            background-color: transparent;
            margin-left: auto;
            border-bottom: none;
            border-right: none;
            justify-content: flex-end;

            li {
                border: 0 !important;
                background-color: transparent !important;
                padding: 0 20px !important;

                &:after {
                    content: unset;
                }

                @media screen and (max-width: @header-mobile-width) {
                    display: block;
                }


                &:hover {
                    a {
                        color: @header-menu-color-hover;
                    }

                    border-color: @header-menu-color-hover  !important;
                }

                a {
                    color: @header-menu-color;
                    font-weight: 400;
                }
            }
        }

        &-languages {
            margin-left: 0 !important;
            max-width: 100%;
            padding: 0 10px;


            @media screen and (max-width: @header-mobile-width) {
                margin-left: auto !important;
            }
        }
    }
    .menu-burger-open {
        li {
            display: flex;
            align-items: center;
            height: 40px !important;
            line-height: 40px;
            margin-bottom: 8px;
            margin-top: 4px;
        }
    }
    .menu-burger-button {
        position: absolute;
        width: auto;
        height: auto;
        top: calc(@header-size / 4);
        right: calc(@header-size / 4);

        @media only screen and (min-width: @header-mobile-width) {
            display: none;
        }
        @media only screen and (max-width: @header-mobile-width) {
            top: calc(@header-size-mobile / 4);
            right: 40px;

        }

        .burger {
            height: calc(@header-size-mobile / 2);
        }
    }

    .menu-burger {
        @media only screen and (min-width: @header-mobile-width) {
            display: none;
        }
    }

    .menu-level-1 {
        /* SAMPLE OF MENU CUSTOM LEVEL */
    }
}

.ant-menu-sub,
.ant-menu-submenu-popup {
    border-radius: 2px !important;
}

.header-burger-open {
    .menu {
        display: block;
        position: fixed;
        width: 100%;
        height: calc(100% - @header-size);
        top: @header-size;
        left: 0;
        right: 0;
        z-index: 9998;
        background-color: @mobile-menu-background;
        padding: @mobile-menu-padding-vertical @mobile-menu-padding-horizontal;
        overflow: auto;
        margin-top: 0;

        @media only screen and (max-width: @header-mobile-width) {
            height: calc(100% - @header-size-mobile);
            top: @header-size-mobile;
        }
    }
}

.burger .burger-lines,
.burger .burger-lines:after,
.burger .burger-lines:before {
    background-color: @header-menu-color !important;
}

.burger.burger-slip.open .burger-lines{
  background-color: initial !important;
}

@primary-color: #5865F2;@font-size-base: 20px;@border-radius-base: 20px;
@import "../../styles/variables.less";

.cookies {
    position: absolute;
    height: 100px;
    background-color: #777777;
    &--popup {
        position: fixed;
        z-index: 10000;
        width: 100%;
        height: auto;
        bottom: 0;
        background-color: #010119!important;
        box-shadow: 0 -1px 10px 0 rgba(172, 171, 171, 0.3);
        &__content {
            width: 90%;
            margin: 0 auto;
            color: rgba(0, 0, 0, 0.85);
            padding: 20px calc((100% - 1200px) / 2);
            display: flex !important;
            justify-content: space-between;
            flex-wrap: wrap;

                display: block;
                p {
                    margin-bottom: 1em;
                    color: @header-menu-color;
                }
        }
    }
}

@primary-color: #5865F2;@font-size-base: 20px;@border-radius-base: 20px;
@import "../../../styles/variables.less";

.listing {

    &_tab-list {
        padding-bottom: 120px;
        padding-top: 80px;

        &:last-child {
            padding-bottom: 0;
        }

        &:nth-child(even) {
           flex-direction: row-reverse;
        }

        &-image {
            img {
                width: 100%;
                object-fit: cover;
                height: 100%;
            }
        }

        &-text {
            h1 {
                font-size: @font-heading;
                font-weight: 700;
                color: #fff;

            }
            
            p {
                font-size: 1rem;
                color: rgb(156 163 175);
            }
        }

        .btn-explorer {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 240px;
            font-size: 16px;
            outline: 0;
            line-height: 19px;
            padding: 16px;
            border-radius: 5px;
            transition: all .1s ease-in;
            background: #5865f2;
            font-weight: 700;
            color: #fff;
            cursor: pointer;
            text-decoration: none;

            &:hover {
                opacity: 0.8;
                text-decoration: none;
                color: #fff;
            }
        }
    }

    &_operation-netuno {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        position: relative;
        grid-gap: 32px;
        z-index: 0;
        width: 100%;

        .listing__item__operation-netuno {
            background: #121214;
            margin-top: 64px;
            padding: 32px;
        }
        
        @media only screen and (max-width: @header-mobile-width) {
            display: flex !important;
            flex-wrap: wrap;
            align-items: center;
            padding: 0 20px;
            
        }

        .listing__image {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 50%;

            img {
                padding: 0 !important;
                max-width: 100%;
                display: block;
                object-fit: cover;
                height: auto !important;
            }
        }

        span {
            color: @font-color-primary;
            font-size: 24px;
            width: 356px;
            line-height: 40px;
            font-weight: 700;
            text-align: left !important;
        }

        p {
            line-height: 1.5rem;
            color: @font-gray;
            font-size: @font-subparagraph;
            font-weight: 400;
            padding-top: 15px;
        }
    }

    &__news-at-sitana {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        place-items: center;
        position: relative;
        margin: 0 auto;
        grid-gap: 5rem;
        z-index: 0;

        @media only screen and (max-width: @header-mobile-width) {
            display: flex;
            flex-direction: column !important;
            margin: 0 auto;
            overflow: hidden;
            padding-top: 40px !important;

            .listing__image {
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 100%;

            }
    
            img {
                width: 350px !important; 
                height: auto;
                padding: 15px !important;
                object-fit: cover;
            }
        }


        .listing__item {
            background-color: #121214 !important;
            border: 1 solid #29292e;
            border-radius: 4px;
            flex-direction: row !important;
            height: auto;

            @media only screen and (max-width: @header-mobile-width) {
                width: 100% !important; 
                display: inline-flex;
                height: 100%;
                flex-direction: column !important;

                .listing__text {
                    text-align: left !important;
                }

                .listing__image {
                    
                    max-width: 156px !important;
                    margin: 0 auto;
        
                }
            }
        }

        h1 {
            text-align: left !important;
            font-weight: 700;
            color: @font-color-primary;
            padding: 5px 15px 10px 15px;
            margin: 0;
        }

        p {
            font-weight: 400;
            padding: 0 5px 10px 5px;
            color: @font-gray;

        }

        .listing__image {
            display: inline-block;
            max-width: 100%;
            position: relative;
            box-sizing: border-box;
            margin: 0;
            margin-bottom: 0px;
            margin-left: 0px;
            width: 156px;
            margin: 15px 5px 0 15px;


            img {
                max-width: 100%;
                padding: 0;
                margin: 0 0 1.45rem;
                margin-right: 0px;
                margin-left: 0px;
                object-fit: cover;
            }
        }
    }

    &__cases-listing {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        place-items: center;
        position: relative;
        margin: 0 auto;
        grid-gap: 5rem;
        z-index: 0;
        width: 80%;
        padding-bottom: 128px !important;

        @media only screen and (max-width: @header-mobile-width) {
            display: flex;
            flex-direction: column !important;
            margin: 0 auto;
            overflow: hidden;
            padding: 0 20px;
            .listing__image {
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 100%;

            }
    
            img {
                padding: 15px !important;
            }
        }

        .listing__item {
            background-color: #121214 !important;
            border: 1.5px solid #29292e;
            border-radius: 4px;
            flex-direction: row !important;
            width: 100%;
        }

        .technology {
            color: #5b5ddf;
            font-size: 17px !important; 
            font-weight: 400 !important;
        }

        h1 {
            text-align: left !important;
            font-weight: 700;
            color: @font-color-primary;
            padding: 15px !important;
            margin: 0;
        }

        p {
            font-weight: 400;
            padding: 0 5px 10px 5px;
            margin: 0 10px 10px 10px;
            color: @font-gray;
            @media only screen and (max-width: @header-mobile-width) {
                font-size: 17px;
            }

        }
        a {
            text-decoration: none !important;
            display: block;
            width: 100%;
            list-style: none;
            &:hover {
              box-shadow: 0 -1px 45px rgba(172, 171, 171, 0.3);
            }
        }

        .listing__image {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
        }

        img {
            max-width: 100%;
            padding: 0;
            margin: 0 0 1.45rem;
            margin-right: 0px;
            margin-left: 0px;
            height: auto;
        }
    }

    &__support-listing{
        max-width: 1344px;
        position: relative;
        margin: 0 auto;
        box-sizing: border-box;
        width: 100%;
        padding-bottom: 78px;
    
        @media (min-width: 900px) and (max-width: 1199.98px) { 
            .support {
                .support-text {
                    max-width: 350px;
                }
            }
        }
       
        .support {
            display: -webkit-flex;
            display: -moz-box;
            display: flex;
            -webkit-justify-content: space-between;
            -moz-box-pack: space-between;
            justify-content: space-between;
            align-items: center;
            gap: 40px !important;
            padding-top: 78px;
    
            &:nth-child(even ) {
                flex-direction: row-reverse;
            }
    
            @media (min-width: 900px) and (max-width: 1200px) {
                max-width: 800px;
                margin: 0 auto;
                padding: 40px 20px;
             }
    
            @media only screen and (max-width: 900px) {
                
                display: flex;
                flex-direction: column !important;
                margin: 0 auto !important; 
                align-items: center;
                justify-content: center !important;
                
                .support-text {
                    text-align: center;
    
                    p {
                        margin: 0;
                    }
                }
    
                .support-image {
                    margin: 0 !important;
                    display: flex;
                    justify-content: center;
                    img {
                        max-width: 300px !important;
                        width: 100%;
                    }
                }
            }
    
           
        }
    
        .support-text {
            order: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left !important;
            max-width: 550px;
            margin-left: 40px;
    
            .text-xxs {
                color: #fff;
            }
    
            h1 {
                font-size: 44px;
                font-weight: 58px;
                color: #fff;
                font-weight: 700;
                margin: 0;
            }
            
            b {
                font-size: 20px;
                color: #fff;
            }
    
            p {
                font-size: 20px !important;
                color: #c1c1c1;
                font-weight: 400;
                margin: 0;
                line-height: 32px;
            }
            
            ul {
                list-style: none;
            }

            li {
                font-size: 20px !important;
                color: #c1c1c1;
                font-weight: 400;
                margin: 0;
                line-height: 32px;
            }
            li::before 
            {
                content: "•"; 
                color: #c1c1c1; 
                width: 1em !important;
            }
        }
        
        .support-image {
            order: 1;
            display: block;
            height: auto;
            width: 450px;
            max-width: 100%;
    
            > div {
                display: -webkit-flex;
                display: -moz-box;
                display: flex;
                -webkit-align-self: start;
                align-self: start;
    
                img {
                    max-width: 100% !important;
                    display: block;
                    object-fit: cover;
                    width: auto;
                    height: auto;
                }
            }
        }

        .support:nth-child(2) {
            margin-top: 50px;
            .support-text {
                margin-left: 0;
                margin-right: 40px;
                @media only screen and (max-width: 900px) {
                    margin-left: 40px;
                    margin-right: 0;
                }
            }
        }
    }

    &__listing-sociais {

        li {
            display: flex;
            flex-direction: row !important;
            gap: 15px;
            place-items: center;
            align-items: center;
            justify-content: center;
            margin: 0 auto;

            @media only screen and (max-width: @header-mobile-width) {
                flex-direction: column !important;
            }
        }
        h1 {
            text-align: center !important;
            margin: 0 auto;
            color: #fff;
            font-weight: 700;
            font-size: @font-subparagraph;
        }

        .sitemap {
            display: grid;
            grid-template-columns: repeat(6,1fr);
            margin-top: -5px;
            gap: 8px;
        
            p {
                display: -webkit-flex;
                display: -moz-box;
                display: flex;
                -webkit-justify-content: center;
                -moz-box-pack: center;
                justify-content: center;
                -moz-box-align: center;
                align-items: center;
                border-radius: 5px;
                -webkit-transition: all .3s ease;
                transition: all .3s ease;
            }
        
            a {
              display: flex;
              color: #68a2ff;
              text-decoration: none;
              background: #121214;
              -webkit-align-items: center;
              -moz-box-align: center;
              align-items: center;
              border-radius: 5px;
              -webkit-transition: all .3s ease;
              transition: all .3s ease;
    
              &.share-icons {
                color: #eeeeee;
        
                svg {
                  height: 32px;
                  width: auto;
                  padding: 5px;
                }
              }
        
              &:hover,
              &:focus {
                color: #1178FF;
                text-decoration: none;
              }
            }
        
            span {
              flex: 1;
            }
          }
        }
        
        .footer-icons {
          height: 14px;
          margin-right: 10px;
        }
        
        @media (max-width: 590px) {
          .sitemap {
            flex-direction: column;
        
            div:nth-child(2) {
              margin-top: 24px;
            }
        }
    }
    
    &__funcionality-netuno {
        width: 100%;
        
        .grid-container:first-of-type {
            padding-top: 8rem !important;
        }

        .grid-container {
            display: flex;
            padding-bottom: 160px;
            &:nth-child(odd) {
                flex-direction: row-reverse;
            }
            &-text {    

                h1 {
                    font-size: @font-heading;
                    color: #fff;
                    font-weight: 700;
                }

                p {
                    color: #e1e1e6;

                }
                
            }

            &-image {
                width: 100%;

                img {
                    object-fit: cover;
                    width: 100%;
                }
            }
        }
       
       
    }
}

@primary-color: #5865F2;@font-size-base: 20px;@border-radius-base: 20px;
@import "../../../styles/variables.less";

.actions__item {
    max-width: 240px;
    height: 64px;
    font-size: 16px;
    line-height: 19px;
    padding: 20px;
    margin: 0 auto !important;

        @media only screen and (max-width: @header-mobile-width) {
            margin: 0 auto !important;
        }

    border: 1.5px;
    border-style: solid;
    border-radius: 5px;
    transition: all .1s ease-in;
    border-color: #29292e;
    background: #121214;
    &:hover {
        cursor: pointer;
        background-color: @actions-item-background-hover;
        color: @actions-item-text-color-hover;
        a {
            color: @actions-item-link-color-hover;
        }
    }

    h4 {
        color: @actions-item-text-color;
        font-size: 22px;
        text-transform: uppercase;
        text-align: center !important;
        font-weight: 700;
    }
    p {
        font-size: 1.0em;
        margin-bottom: 0.5em;
    }
    a {
        text-transform: uppercase;
        font-size: 1.0em;
        color: @actions-item-link-color;
    }
}

@primary-color: #5865F2;@font-size-base: 20px;@border-radius-base: 20px;